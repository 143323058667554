import React, { useEffect, useState } from 'react';
import { useAlert } from '../../ProHelpers/AlertContext'; // Import the useAlert hook
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import LoadingPopup from '../../ProHelpers/LoadingPopup';

function RegistrationForm({ formData, setFormData }) {
    const { showAlert } = useAlert();
    const [serverZones, setServerZones] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("Please wait, loading...");



    // Function to fetch server zones
    const fetchServerZones = async () => {
        setLoading(true);
        setMessage("Fetching data...");
        try {
            const response = await AxiosInstance.get('/CustomerServerZone'); // Adjust the path to your API
            console.log("ENV value: ", process.env.REACT_APP_BASE_URL);
            if (response?.data?.data && Array.isArray(response.data.data)) {
                setServerZones(response.data.data); // Adjust based on your response structure
            } else {
                console.log('Unexpected data format ', response);
            }
        } catch (err) {
            console.error('Error fetching server zones:', err); // Log the full error for more details
            setMessage("Error loading data. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleAccountTypeChange = (event) => {
        const selectedType = event.target.value;
        setFormData(prevData => ({
            ...prevData,
            AccountType: selectedType,
            RegionName: ''
        }));

        if (selectedType === 'Enterprise') {
            fetchServerZones(); // Fetch server zones for Enterprise
        } else if (selectedType === 'Trial') {
            // Default to "US" for Trial and clear previous server zones
            setFormData(prevData => ({
                ...prevData,
                RegionName: 'US| |ind-dev.cv2akao40by5.us-east-2.rds.amazonaws.com'
            }));
            setServerZones([{ SNo: 1, Country: 'US', City: '', ServerEndPointURL: 'ind-dev.cv2akao40by5.us-east-2.rds.amazonaws.com' }]);
        }
    };

    // Handle region selection
    const handleRegionChange = (event) => {
        const selectedValue = event.target.value;
        const selectedZone = serverZones.find(zone =>
            `${zone.Country}|(${zone.City})|${zone.ServerEndPointURL}` === selectedValue
        );

        if (selectedZone && !selectedZone.ServerEndPointURL) {
            showAlert("We are coming up with these DC's in this region.", 'warning');
            return;
        }

        setFormData(prevData => ({
            ...prevData,
            RegionName: selectedValue,
        }));
    };

    useEffect(() => {
        if (formData.AccountType === 'Trial') {
            setFormData(prevData => ({
                ...prevData,
                RegionName: 'US| |ind-dev.cv2akao40by5.us-east-2.rds.amazonaws.com'
            }));
            setServerZones([{
                SNo: 1,
                Country: 'US',
                City: '',
                ServerEndPointURL: 'ind-dev.cv2akao40by5.us-east-2.rds.amazonaws.com'
            }]);
        } else if (formData.AccountType === 'Enterprise') {
            fetchServerZones();
        }
    }, [formData.AccountType]);

    return (
        <div>
            {loading && <LoadingPopup message={message} />}
            <div className="form-row-pagetwo">
                <div className="account-type-section">
                    <label className="account-type-label">Account Type:</label>
                    <div className="radio-group">
                        <label>
                            <input
                                type="radio"
                                name="accountType"
                                value="Enterprise"
                                checked={formData.AccountType === 'Enterprise'}
                                onChange={handleAccountTypeChange}
                            /> Enterprise
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="accountType"
                                value="Trial"
                                checked={formData.AccountType === 'Trial'}
                                onChange={handleAccountTypeChange}
                            /> Trial
                        </label>
                    </div>

                </div>
            </div>
            {formData.AccountType === 'Enterprise' && (
                <div className="form-row">
                    <input
                        type="text"
                        className="company-input"
                        placeholder="Enter Company Name"
                        value={formData.CustomerName}
                        onChange={(event) => setFormData(prevData => ({
                            ...prevData,
                            CustomerName: event.target.value
                        }))}
                    />
                    <input
                        type="text"
                        className="coupon-input"
                        placeholder="Enter Coupon"
                        value={formData.coupon}
                        onChange={(event) => setFormData(prevData => ({
                            ...prevData,
                            coupon: event.target.value
                        }))}
                    />
                </div>)}


            {formData.AccountType === 'Enterprise' && (
                <div className="form-row">
                    <select
                        id="serverZones"
                        className="region-dropdown"
                        value={formData.RegionName}
                        onChange={handleRegionChange}
                    >
                        <option value="">DataCenter Region</option>
                        {serverZones.map(zone => (
                            <option key={zone.SNo} value={`${zone.Country}|(${zone.City})|${zone.ServerEndPointURL}`}>
                                {`${zone.Country} (${zone.City})`}
                            </option>
                        ))}
                    </select>
                </div>
            )}
            {formData.AccountType === 'Trial' && (
                <div className="form-row">
                    <select
                        id="serverZones"
                        className="region-dropdown"
                        value={formData.RegionName}
                        disabled
                    >
                        <option value="US| |ind-dev.cv2akao40by5.us-east-2.rds.amazonaws.com">US</option>
                    </select>
                </div>
            )}

        </div>
    );
}

export default RegistrationForm;
